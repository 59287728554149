import React, { Component } from "react";
import { hashHistory, Link } from "react-router";
import "./product.css";
import PicSwiper from "../../components/PicSwiper/PicSwiper";
import HeadNav from "../../components/HeadNav/index";
import Footer from "../../components/Footer/index";

const picList = ["./image/home_banner_2.png", "./image/home_banner_3.png"];

//comic ---> refrige
const comicList = [
  { comicName: "490C5-U 409升大屏急冷式冰箱", 
  comicImg: "./image/refrige/refrige1.png",
   link: "/music" },
  {
    comicName: "BCD-486WPJD星玄青 双变频风冷十字冰箱",
    comicImg: "./image/refrige/refrige2.png",
    link: "/game",
  },
  {
    comicName: "R505Q6-UA 双变频十字/对开门智屏电冰箱",
    comicImg: "./image/refrige/refrige3.png",
    link: "/preform",
  },
  {
    comicName: "BCD-519WEZ50 对开门风冷无霜冰箱",
    comicImg: "./image/refrige/refrige4.png",
    link: "/comic",
  },
  {
    comicName: "R501Q2-U琉璃紫 501升大冰箱",
    comicImg: "./image/refrige/refrige5.png",
    link: "/match",
  },
];

//music --->TV
const musicList = [
  {
    id: "1",
    musicName: "98X9C 98英寸IMAX巨幕私人影院智屏电视",
    musicPath: "./music/music_01.mp3",
    musicPic: "./image/TV/TV1.png",
    status: "1",
  },
  {
    id: "2",
    musicName: "65C12E 65英寸灵悉QD-MiniLED智屏",
    musicPath: "./music/music_02.mp3",
    musicPic:  "./image/TV/TV2.png",
    status: "1",
  },
  {
    id: "3",
    musicName: "65Q9E 65英寸 QLED原色量子点智屏电视",
    musicPath: "./music/music_03.mp3",
    musicPic: "./image/TV/TV3.png",
    status: "1",
  },
  {
    id: "4",
    musicName: "55T8E-Pro 55英寸 QLED原色量子点智屏电视",
    musicPath: "./music/music_04.mp3",
    musicPic: "./image/TV/TV4.png",
    status: "1",
  },
  {
    id: "5",
    musicName: "65T9E 65英寸 QLED原色量子点游戏智屏电视",
    musicPath: "./music/music_05.mp3",
    musicPic:"./image/TV/TV5.png",
    status: "1",
  },
];

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectType: 0,
    };
  }

  selectMenuChange = (selectType) => {
    this.setState({
      selectType: selectType,
    });
  };

  cateHandle = (item) => {
    hashHistory.push(item.link);
  };

  getMusicListHtml = () => {
    let musicListHtml = musicList.map(function(item, index) {
      return (
        <div className="cate-item" key={index}>
          <Link to="/tv">
            <img src={item.musicPic} className="cate-img" alt="" />
            <div className="cate-name">{item.musicName}</div>
          </Link>
        </div>
      );
    });
    return musicListHtml;
  };

  getComicListHtml = () => {
    let comicListHtml = comicList.map(function(item, index) {
      return (
        <div className="cate-item" key={index}>
          <Link to="/refrige">
            <img src={item.comicImg} className="cate-img" alt="" />
            <div className="cate-name">{item.comicName}</div>
          </Link>
          
        </div>
      );
    });
    return comicListHtml;
  };

  render() {
    return (
      <div>
        <HeadNav selectKey="2" />
        <div className="product-contain">
          <PicSwiper picList={picList} />
          <div className="cate-list">
            <div className="cate-title">电视</div>
            {this.getMusicListHtml()}
          </div>

          <div className="cate-list">
            <div className="cate-title">冰箱</div>
            {this.getComicListHtml()}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;
