import React, { Component } from 'react';
import {Link} from "react-router";
import './comic.css';
import HeadNav from '../../components/HeadNav';
import Footer from '../../components/Footer/index'


const comicList =[
    {"comicPicUrl":"./image/refrige/refrige1.png",
        "comicName":"490C5-U 409升大屏急冷式冰箱","gameSummary":"21.5吋全高清智屏/远场语音人脸识别/极速制冷/影音娱乐/巡航杀菌/精美菜谱"
    },{"comicPicUrl":"./image/refrige/refrige2.png",
    "comicName":"BCD-486WPJD星玄青 双变频风冷十字冰箱","gameSummary":"AAT负离子养鲜/一体式双变频/风冷无霜/电脑控温/急速制冷/节能静音"
    },{"comicPicUrl":"./image/refrige/refrige3.png",
        "comicName":"R505Q6-UA 双变频十字/对开门智屏电冰箱","gameSummary":"21.5吋智屏/影音娱乐/全屋智控/快速制冷/智能恒温养鲜/膳食管理"
    },{"comicPicUrl":"./image/refrige/refrige4.png",
        "comicName":"BCD-519WEZ50 对开门风冷无霜冰箱","gameSummary":"电脑智控/静音低噪/519升大空间/63cm纤薄机身/风冷无霜。    "
    },{"comicPicUrl":"./image/refrige/refrige5.png",
        "comicName":"R501Q2-U琉璃紫 501升大冰箱","gameSummary":"渐变琉璃子机身/智能恒温养鲜/三档变温舱/多点离子杀菌/快速制冷锁鲜"
    },{"comicPicUrl":"./image/refrige/refrige6.png",
        "comicName":"R510C12-UA 510升十字对开急冷智屏冰箱","gameSummary":"极速制冷/膳食管理/21.5寸智屏/33分贝静音/厨房娱乐体验/智能恒温养鲜"
    }];

class Comic extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    getComicListHtml = () => {
        let comicListHtml = comicList.map(function(item,index){
            let itemStyle={}
            return(
                <div className="comic-item" key={index} style={itemStyle}>
                    <div className="comic-pic">
                        <img src={item.comicPicUrl}  alt="" />
                    </div>
                    <div className="comic-info">
                        <div className="comic-name">{item.comicName}</div>
                        <div className="comic-desc">
                            {item.gameSummary}
                        </div>
                    </div>

                    {/* <div className="comic-download">
                        <a href={item.comicPicUrl} download>
                           <span>下载</span>
                        </a>
                    </div>

                    <Link to={{pathname:"/comic_detail",query:{index:index}}}>
                        <div className="take-detail-btn">查看</div>
                    </Link> */}
                </div>
            )
        });
        return comicListHtml;
    };

    render() {
        return (
            <div>
                <HeadNav selectKey="4" />
                <div className="comic-contain">
                    <div className="comic-list">
                        {this.getComicListHtml()}
                        <div className="clear"></div>
                    </div>
                </div>
                <Footer />
            </div>
            );
        }
    }

export default Comic;
