import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, hashHistory, IndexRoute } from "react-router";
import "antd/dist/antd.css";
import "./index.css";
import App from "./App";
import Home from "./container/Home/home";
import Game from "./container/Game/game";
import Preform from "./container/Preform/preform";
import Comic from "./container/Comic/comic";
import Music from "./container/Music/music";
import MusicDetail from "./container/Music/musicDetail";
import PreformDetail from "./container/PreformDetail/preformDetail";
import ComicDetail from "./container/ComicDetail/comicDetail";
import Recharge from "./container/Recharge/recharge";
import Match from "./container/Match/match";
import MatchDetail from "./container/MatchDetail/matchDetail";
import MemberBuy from "./container/MemberBuy/memberBuy";
import TvPlay from "./container/TvPlay/TvPlay";
import DrugList from "./container/DrugList/drugList";
import DiseaseList from "./container/DiseaseList/diseaseList";
import product from './container/Product/product'
import TV from './container/TV/tv'
import * as serviceWorker from "./serviceWorker";
import { Layout } from "antd";
const { Content } = Layout;

ReactDOM.render(
  <Layout>
    <Content style={{ padding: "0px", marginTop: "0" }}>
      <Router onUpdate={() => window.scrollTo(0, 0)} history={hashHistory}>
        <Route path="/" component={App}>
          <IndexRoute component={Home}> </IndexRoute>
          <Route path="/home" component={Home} />
          <Route path="/product" component={product} />
          <Route path="/tv" component={TV} />
          <Route path="/refrige" component={Comic} />

          <Route path="/music" component={Music} />
          <Route path="/music_detail" component={MusicDetail} />
          <Route path="/game" component={Game} />
          <Route path="/preform" component={Preform} />
          <Route path="/preform_detail" component={PreformDetail} />
          <Route path="/comic_detail" component={ComicDetail} />
          <Route path="/recharge" component={Recharge} />
          <Route path="/match" component={Match} />
          <Route path="/matchDetail" component={MatchDetail} />
          <Route path="member_buy" component={MemberBuy} />
          <Route path="/tv_play" component={TvPlay} />
          <Route path="/drug" component={DrugList} />
          <Route path="/disease" component={DiseaseList} />
          <Route path="*" component={Home} />
        </Route>
      </Router>
    </Content>
  </Layout>,
  document.getElementById("root")
);

serviceWorker.unregister();
