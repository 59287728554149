import React, { Component } from "react";
import "./drugList.css";
import HeadNav from "../../components/HeadNav";
import Footer from "../../components/Footer/index";
import Drags from "../../components/Drug/drug";
class DrugList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <HeadNav selectKey="8" />
        <div className="drug-contain">
          <div className="drug-list">
            <Drags />
            <div className="clear" />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default DrugList;
