import React, { Component } from 'react';
import { Link } from 'react-router';
import './game.css';
import {List,Spin} from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import HeadNav from '../../components/HeadNav';
import Footer from '../../components/Footer/index'


const gameList =[{"gameThumUrl":"./image/game_thum_icon_1.png",
        "gameApkUrl":"./game/game_apk.apk",
        "gameName":"小鸟OL","gameDesc":"是一款跨平台的移动策略游戏。集社交、策略、养成、收集等功能为一体，玩法多样，画面可爱，内容丰富。",
        "gamePicListUrl":["./image/game_pic_1_1.png","./image/game_pic_1_2.png","./image/game_pic_1_3.png"]
    },{"gameThumUrl":"./image/game_thum_icon_2.png",
        "gameApkUrl":"./game/game_apk.apk",
        "gameName":"3D开车教室","gameDesc":"是一款3D模拟驾驶游戏，玩家可以操控汽车的方向盘，可以按各种指示灯，给你最真实的驾驶体验，在公路上行驶时玩家需要遵守交通规则，什么时候要减速什么时候要打灯都要知道。",
        "gamePicListUrl":["./image/game_pic_2_1.png","./image/game_pic_2_2.png","./image/game_pic_2_3.png"]
    },{"gameThumUrl":"./image/game_thum_icon_3.png",
        "gameApkUrl":"./game/game_apk.apk",
        "gameName":"宝宝小警察","gameDesc":"是针对小朋友开发的儿童游戏，宝宝小警察游戏采用有趣、简单的模拟玩法，旨在让小朋友们可在游戏中学习知识、锻炼能力等。。",
        "gamePicListUrl":["./image/game_pic_3_1.png","./image/game_pic_3_2.png","./image/game_pic_3_3.png"]
    }];

class Game extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list:gameList,
            hasMore:true,
            loading:false
        }
    }

    getGamePicListHtml = (gamePicList) => {
        let gamePicListHtml = gamePicList.map(function(item,index){
            let picItemStyle={};
            if(index === 0){
                picItemStyle.marginLeft="0"
            }else if(index === gamePicList.length - 1){
                picItemStyle.marginRight="0"
            }

            return(
                <div className="game-pic-item" key={index} style={picItemStyle}>
                    <img src={item} className="pic-item"  alt=""/>
                </div>
            )
        });
        return gamePicListHtml;
    };

    handleInfiniteOnLoad = () =>{
        // console.log("handleInfiniteOnLoad");
        // let list = this.state.list;
        // this.setState({loading:true,list :list.concat(gameList)});
        // setTimeout(()=>{
        //     this.setState({loading:false});
        // },2000)
    };


    getGameListHtml = () => {
        let that = this;
        let gameListHtml = gameList.map(function(item,index){
            let itemStyle = {};
            if(index > 0){
                itemStyle.marginTop = "40px";
            }
            return(
                <div className="game-item" key={index} style={itemStyle}>
                    <div className="game-info">
                        <div className="game-thum-icon">
                            <img src={item.gameThumUrl} className="game-img"  alt="" />
                        </div>
                        <div className="game-name">{item.gameName}</div>
                        <div className="game-desc">{item.gameDesc}</div>
                        <Link to="/recharge">
                            <div className="charge-btn">充值</div>
                        </Link>
                        <div className="download-btn">下载</div>
                    </div>
                    <div className="game-pic-list">
                        {that.getGamePicListHtml(item.gamePicListUrl)}
                    </div>
                </div>
            )
        });
        return gameListHtml;
    };

    render() {
        const list = this.state.list;
        return (
            <div>
                <HeadNav selectKey="3"/>
                <div className="game-contain">
                    <div className="game-list">
                        <InfiniteScroll
                            initialLoad={false}
                            pageStart={0}
                            loadMore={this.handleInfiniteOnLoad}
                            hasMore={!this.state.loading && this.state.hasMore}
                            useWindow={false}
                        >
                        <List
                            dataSource={list}
                            renderItem={(item,index) => (
                                <List.Item key={item.id}>
                                    <div className="game-item" key={index}  >
                                        <div className="game-info">
                                            <div className="game-thum-icon">
                                                <img src={item.gameThumUrl} className="game-img" alt=""/>
                                            </div>
                                            <div className="game-name">{item.gameName}</div>
                                            <div className="game-desc">{item.gameDesc}</div>
                                            <Link to="/recharge">
                                                <div className="charge-btn">充值</div>
                                            </Link>
                                            <a href={item.gameApkUrl} download>
                                                <div className="download-btn">下载</div>
                                            </a>
                                        </div>
                                        <div className="game-pic-list">
                                            {this.getGamePicListHtml(item.gamePicListUrl)}
                                        </div>
                                    </div>
                                </List.Item>
                            )}
                        >
                            {this.state.loading && this.state.hasMore && (
                                <div className="loading-container">
                                    <Spin />
                                </div>
                            )}
                        </List>

                        </InfiniteScroll>
                    </div>
                </div>
                <Footer />
            </div>
            );
        }
    }

export default Game;
