import React, { Component } from "react";
import "./index.css";
import { Layout } from "antd";
const { Footer } = Layout;

export default class CusFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Footer
        style={{ textAlign: "center", fontSize: "12px", lineHeight: "20px" }}
      >
        <div style={{fontSize:14}}>
          <span>深圳TCL新技术有限公司 {'\u00A0'}</span>
          <a href="http://beian.miit.gov.cn/" target={"_blank"}>
            <img width="15px" height="15px" src="http://official-o.img.leiniao.com/official/pcimg/beian_ico.png" alt="深圳TCL新技术有限公司"
            style={{verticalAlign:"middle"}}/>粤ICP备14002670号</a>
        </div>
      </Footer> 
    );
  }
}
