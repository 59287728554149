import React, { Component } from "react";
import "./index.css";
import { Layout, Menu } from "antd";
import { hashHistory } from "react-router";
import Login from "../Login/index";
import Register from "../Register/index";
import VipModal from "../VipModal/index";

const { Header } = Layout;

export default class HeadNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectKey: props.selectKey,
      loginVisible: false,
      registerVisible: false,
      vipModalVisible: false,
    };
  }

  handleClick = (e) => {
    if (e.key === "1") {
      hashHistory.push("/");
    } else if (e.key === "2") {
      hashHistory.push("/product");
    } else if (e.key === "3") {
      hashHistory.push("/tv");
    } else if (e.key === "4") {
      hashHistory.push("/refrige");
    } 
  };

  cancelHandle = () => {
    this.setState({
      loginVisible: false,
      registerVisible: false,
    });
  };

  closeHandle = () => {
    this.setState({
      vipModalVisible: false,
    });
  };

  render() {
    return (
      <Header style={{ position: "fixed", zIndex: 1000, width: "100%" }}>
        <div className="logo">
          {/* <img src="./image/haoke-logo.png" alt="" /> */}
          <span>深圳TCL新技术有限公司</span>
        </div>

        <Menu
          theme="light"
          mode="horizontal"
          defaultSelectedKeys={[this.state.selectKey]}
          onClick={this.handleClick}
          style={{ lineHeight: "64px" }}
        >
          {/*<Menu.Item key="7">演出剧</Menu.Item>*/}
          <Menu.Item key="1">首页</Menu.Item>
          <Menu.Item key="2">产品</Menu.Item>
          <Menu.Item key="3">电视</Menu.Item>
          <Menu.Item key="4">冰箱</Menu.Item>
        </Menu>

        {/*<div className="vip-buy" onClick={()=>{*/}
        {/*this.setState({vipModalVisible:true});*/}
        {/*}}>会员购买</div>*/}

        <div className="register-login">
          <div
            className="register"
            onClick={() => {
              this.setState({ registerVisible: true });
            }}
          >
            注册
          </div>
          <div
            className="login"
            onClick={() => {
              this.setState({ loginVisible: true });
            }}
          >
            登录
          </div>
        </div>
        <Login
          visible={this.state.loginVisible}
          cancelHandle={this.cancelHandle}
        />
        <Register
          visible={this.state.registerVisible}
          cancelHandle={this.cancelHandle}
        />
        <VipModal
          visible={this.state.vipModalVisible}
          closeHandle={this.closeHandle}
        />
      </Header>
    );
  }
}
