import React, { Component } from 'react';
import './preformDetail.css';

const preformDetail=[{
    "preformName":"方块熊简笔画-蝴蝶",
    "preformPicList":["./video/preform_detail_1.png","./image/preform_detail_2.png"],
    "videoUrl":"./video/preform_01.mp4",
    "preformDesc":"《方块熊简笔画》是方块熊乐园新推出的一款教小朋友们学画简笔画的节目，节目中选了大量好玩又简单的画作，有自然风景、虫鱼鸟兽也有生活中常见的事物，教小朋友们熟悉日常事物的同时还能培养小朋友们的艺术天分。"
},{
    "preformName":"麦杰克儿歌-美丽新世界",
    "preformPicList":["./image/preform_pic_2.png"],
    "videoUrl":"./video/preform_02.mp4",
    "preformDesc":"麦杰克儿歌，由资深儿童教育心理学专家，顶级的动画及音乐制作团队联手打造，根据04岁幼儿的视听觉发展规律及语言认知能力，诚意制作有营养，有趣味，有审美，有创意，有知识的儿歌。"
},{
    "preformName":"兔小蛋-急救机器人Amber出动",
    "preformPicList":["./image/preform_pic_3.png"],
    "videoUrl":"./video/preform_03.mp4",
    "preformDesc":"兔小蛋玩具专辑为学龄前儿童提供了丰富的玩玩具教学视频内容，里面的趣味玩具可多着呢！如小巧精美的汽车玩具，厨房玩具、沙滩玩具、神奇百变的乐高积木、拼装等等。"
}]



class PreformDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        console.log(this.props.location.query.index);
    }


    render() {
        let index = this.props.location.query.index;
        return (
            <div className="detail-contain">
                <div className="preform-title">{preformDetail[index].preformName}</div>
                <video src={preformDetail[index].videoUrl} controls="controls" className="video-panel">
                </video>
                <div className="detail-desc">{preformDetail[index].preformDesc}</div>
            </div>
            );
        }
    }

export default PreformDetail;
