import React, { Component } from "react";
import "./disease.css";

const diseaseList = [
  {
    name: "飞蚊症",
    desc:
      "飞蚊症指眼前有飘动的小黑影，尤其看白色明亮的背景时更明显，还可能伴有闪光感。飞蚊症基本知识是否属于医保：医保疾病发病部位：眼传染性：无传染性多发人群：所有人群相关症状：眼前有飘动的小黑影并发疾病：玻璃体病变飞蚊症诊疗知识就诊科室：眼科治疗费用：暂无相关资料治愈率：多以控制症状为主治疗周期：暂无相关资料治疗方法：药物治疗飞蚊症去医院必看最佳就诊时间：暂无相关资料就诊时长：暂无相关资料复诊频率/诊疗周期：暂无相关资料就诊前准备：暂无相关资料",
    picUrl:
      "http://pic.39yst.com/group1/M00/28/1D/Pb832ll-nZyABxkNAAC2k8y3-ek3123660",
  },
  ,
  {
    name: "高血脂",
    desc:
      "高血脂是指血脂水平过高，可直接引起一些严重危害人体健康的疾病，如动脉粥样硬化、冠心病、胰腺炎等。高血脂基本知识是否属于医保：医保疾病发病部位：全身传染性：无传染性多发人群：中老年人相关症状：黄色瘤，动脉硬化并发疾病：心血管疾病高血脂诊疗知识就诊科室：内分泌科治疗费用：暂无相关资料治愈率：多以控制症状为主治疗周期：暂无相关资料治疗方法：药物治疗高血脂去医院必看最佳就诊时间：暂无相关资料就诊时长：暂无相关资料复诊频率/诊疗周期：暂无相关资料就诊前...",
    picUrl:
      "http://pic.39yst.com/group1/M00/28/0E/Pb832ll60g2AaXHwAACO6Opuh_84979094",
  },
  {
    name: "毛孔粗大",
    desc:
      "当皮肤老旧角质积聚越多，会使肌肤变厚、变粗糙，毛孔变粗大，肌肤也因为无法顺利吸收水分与保养成分，变得暗沉、干燥，加速刺激油脂分泌量，毛孔会再度变大。此外，随着年龄的增长，皮肤逐渐失去弹性，毛囊周围缺乏支持结构，也很容易使毛孔显得比较大;外油内干的皮肤环境，也会使毛孔变得粗大。平时应该多注意皮肤的日常护养。皮肤组织的老化、萎缩，皮肤的老化是不可避免的，通常到了某个年龄段，皮肤毛孔就会出现松弛，血液循环也会逐渐不流畅，皮肤代谢开始变慢。毛孔粗大基本... ",
    picUrl:
      "http://pic.39yst.com/group1/M00/39/0C/Pb832loXqv2AJZ-TAACU-xnLzGQ2078240",
  },
  {
    name: "头皮痒",
    desc:
      "头皮痒是头皮屑多，头皮真菌感染或者头皮过敏的症状。头皮痒主要由铁色小孢子菌、犬(羊毛样)小孢子菌、奥杜盎小孢子菌、歪斜形小孢子菌及鸡禽类小孢子菌引起。当精神压力大，身体较乏的时候由于头皮供血不足，也可以表现为头皮的瘙痒。初起为局限性白色鳞屑性斑片，其上头发变灰暗，如不治疗则逐渐扩大，其周围出现卫星样小鳞屑斑片。病发根部有白色套样菌鞘，病发常于距头皮0.5cm左右处折断。头皮痒基本知识是否属于医保：非医保疾病发病部位：头部传染性：无传染性多发人群",
    picUrl:
      "http://pic.39yst.com/group1/M00/38/5B/Pb832loLzwmAXNPiAADkxzxf-TY5833588",
  },
];

class Disease extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getDiseaseListHtml = () => {
    let diseaseListHtml = diseaseList.map(function(item, index) {
      return (
        <div className="disease-item" key={index}>
          <img src={item.picUrl} className="disease-img" alt="" />
          <div className="disease-name">
            {item.name}
            <div className="disease-tag">疾病百科</div>
          </div>
          <div className="disease-desc">{item.desc}</div>
        </div>
      );
    });
    return diseaseListHtml;
  };

  render() {
    return <div>{this.getDiseaseListHtml()}</div>;
  }
}

export default Disease;
