import React,{ Component} from 'react';
import HeadNav from "../../components/HeadNav";
import Footer from '../../components/Footer/index'
import './tv.css'

const tvList=[
    {"comicPicUrl":"./image/TV/TV1.png",
        "comicName":"98X9C 98英寸IMAX巨幕私人影院智屏电视","gameSummary":"量子点Pro/安桥音响/无边巨幕屏/银河多分区光学引擎"
    },{"comicPicUrl":"./image/TV/TV2.png",
    "comicName":"65C12E 65英寸灵悉QD-MiniLED智屏","gameSummary":"288分区量子点点控光/A++蝶翼超显屏/QLED量子点/HDR 1400"
    },{"comicPicUrl":"./image/TV/TV3.png",
        "comicName":"65Q9E 65英寸 QLED原色量子点智屏电视","gameSummary":"超薄高色域智屏巨幕版/130%原色高色域/超薄金属全面屏/安桥音响/3GB+64GB"
    },{"comicPicUrl":"./image/TV/TV4.png",
        "comicName":"55T8E-Pro 55英寸 QLED原色量子点智屏电视","gameSummary":"QLED原色量子点/航空铝合金机身/3GB+32GB/四重护眼技术"
    },{"comicPicUrl":"./image/TV/TV5.png",
        "comicName":"65T9E 65英寸 QLED原色量子点游戏智屏电视","gameSummary":"QLED原色量子点/三重120Hz+HDMI 2.1+VRR+ALLM//腾讯START云游戏/四重护眼技术"
    },{"comicPicUrl":"./image/TV/TV6.png",
        "comicName":"55T88E 55英寸 全场景AI声控QLED量子点智屏电视","gameSummary":"量子点AI智屏/QLED原色量子点/超薄金属全面屏/免遥控全场景AI声控/2GB+32GB"
    }];
class TV extends Component {
    constructor(props) {
        super(props);
    }
    getComicListHtml = () => {
    let comicListHtml = tvList.map(function(item,index){
        let itemStyle={}
        return(
            <div className="tv-item" key={index} style={itemStyle}>
                <div className="tv-pic">
                    <img src={item.comicPicUrl}  alt="" />
                </div>
                <div className="tv-info">
                    <div className="tv-name">{item.comicName}</div>
                    <div className="tv-desc">
                        {item.gameSummary}
                    </div>
                </div>
            </div>
        )
    });
    return comicListHtml;
};

render() {
    return (
        <div>
            <HeadNav selectKey="3" />
            <div className="tv-contain">
                <div className="tv-list">
                    {this.getComicListHtml()}
                    <div className="clear"></div>
                </div>
            </div>
            <Footer />
        </div>
        );
    }
}
export default TV;