
import React, {Component} from 'react';
import './index.css';
import {Modal,Form,Input,Icon,Button} from "antd";


export class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible:props.visible
        }
    };

    componentWillReceiveProps(nextProps, nextContext) {
        const visible = this.state.visible;
        if(nextProps.visible !== visible){
            this.setState({
                visible:nextProps.visible
            })
        }
    };

    handleOk = (e) => {
        this.props.cancelHandle();
    };

    handleCancel = (e) => {
       this.props.cancelHandle();
    };

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback("密码和确认密码不一致");
        } else {
            callback();
        }
    }

    handleSubmit = (e) => {
        this.setState({
            visible: false,
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <Modal
                    title="注册"
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    footer={null}
                    width="300px"
                >
                    <Form onSubmit={this.handleSubmit} className="login-form">
                        <Form.Item>
                            {getFieldDecorator('userName', {
                                rules: [{ required: true, message: '用户名不能为空' }],
                            })(
                                <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="用户名" />
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: '密码不能为空' }],
                            })(
                                <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="密码" />
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('confirm', {
                                rules: [{ required: true, message: '确认密码不能为空' },{
                                    validator: this.compareToFirstPassword,
                                }],
                            })(
                                <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="确认密码" />
                            )}
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                注册
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}

export default Form.create()(Register)
