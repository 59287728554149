import React, { Component } from 'react';
import './PicSwiper.css';
import {Carousel} from 'antd';


export default  class PicSwiper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            picList:props.picList,
            showArrow:props.type ? true:false,
            type:props.type || "0",
            showDots:props.showDots || true
        }
    }

    getPicListHtml = (item) => {
        const picList = this.state.picList;
        let picListHtml = picList.map(function(item,index){
            return(
                <div key={index} >
                    <img src={item} className="pic-item" alt="" />
                </div>
            )
        })
        return picListHtml;
    };


    onChange = () =>{

    };
    goPrePicHandle = () =>{
        this.refs.carousel.prev();
    };

    goNextPicHandle = () =>{
        this.refs.carousel.next();
    };
    mouseEnterHandle = () =>{
        const picList = this.state.picList;
        const type = this.state.type;
        if(type === "1"){
            return;
        }
        if(picList.length > 1) {
            this.setState({
                showArrow: true
            })
        }
    };

    mouseLeaveHandle = () =>{
        const type = this.state.type;
        if(type === "1"){
            return;
        }
        this.setState({
            showArrow:false
        })
    };


    render() {
        return (
            <div className="swiper-contain"
                 onMouseEnter={this.mouseEnterHandle}
                 onMouseLeave={this.mouseLeaveHandle}>
                <div className="pic-pre" onClick={() =>{
                    this.goPrePicHandle()
                }} style={{opacity:this.state.showArrow?"1":"0",marginLeft:this.state.type === "1" ? "-150px":"0px"}}>
                    <img src="./image/go_prev.png" alt="" className="go-prev-icon" />
                </div>

                <div className="pic-list">
                    <Carousel afterChange={this.onChange} ref="carousel" dots={this.state.showDots} >
                        {this.getPicListHtml()}
                    </Carousel>
                </div>
                <div className="pic-next"  onClick={() =>{
                    this.goNextPicHandle()
                }} style={{opacity:this.state.showArrow?"1":"0",marginRight:this.state.type === "1" ? "-150px":"0px" }}>
                    <img src="./image/go_next.png" alt="" className="go-next-icon" />
                </div>
            </div>
            );
          }
    }

