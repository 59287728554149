
import React, {Component} from 'react';
import './index.css'

const selectList=[{type:"1",title:"连续包月",money:"12",desc:"原价20元/月,次月续费15元/月"},
    {type:"2",title:"1个月",money:"20",desc:"20元/月"},
    {type:"3",title:"6个月",money:"120",desc:"20元/月"},
    {type:"4",title:"1年",money:"240",desc:"20元/月"}]

export default class VipModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectItem: selectList[0],
            payMode: 1,// 1 微信 2 支付宝
            qaVisible:false,
            qaDesc:'回答：若您使用微信扫码购买，请在微信客户端上登录当时付款的微信账号，再点击【我】>>【支付】>>【选择右上角的三个小圆点】>>【自动扣费】>>找到【豪客数娱会员自动续费】>>点击【关闭服务】，然后根据页面提示进行操作关闭即可。\n' +
                '若您使用支付宝扫码购买，请在支付宝客户端上登录当时付款的支付宝账号，再点击【我的】>>【设置】>>【支付设置】>>【免密支付/自动扣款】>>找到【豪客数娱会员自动扣款】>>点击【关闭服务】，然后根据页面提示进行操作关闭即可。'
        }
    }
    componentDidMount() {
        let qaContent = this.refs.qaContent;
        qaContent.innerHTML=this.html_decode("回答：若您使用微信扫码购买，请在微信客户端上登录当时付款的微信账号，再点击【我】>>【支付】>>【选择右上角的三个小圆点】>>【自动扣费】>>找到【豪客数娱会员自动续费】>>点击【关闭服务】，然后根据页面提示进行操作关闭即可。" +
            "若您使用支付宝扫码购买，请在支付宝客户端上登录当时付款的支付宝账号，再点击【我的】>>【设置】>>【支付设置】>>【免密支付/自动扣款】>>找到【豪客数娱会员自动扣款】>>点击【关闭服务】，然后根据页面提示进行操作关闭即可。");
    }

    html_decode = (str) =>{
        var s = "";
        if(str.length == 0) return "";
        s = str.replace(/&gt;/g, "&");
        s = s.replace(/&lt;/g, "<");
        s = s.replace(/&gt;/g, ">");
        s = s.replace(/&nbsp;/g, " ");
        s = s.replace(/&#39;/g, "\'");
        s = s.replace(/&quot;/g, "\"");
        s = s.replace(/<br>/g, "\n");
        return s;
    }
    getMoneyListHtml = () => {
        const that = this;
        let moneyListHtml = selectList.map(function(item,index){
            let bgStyle={};
            const selectItem = that.state.selectItem;
            if(selectItem.type === item.type){
                bgStyle.border="1px solid #eac714";
            }else{
                bgStyle.border="1px solid #808080";
            }
            return(
                <div className="vip-select-item" style={bgStyle} onClick={()=>{
                    that.setState({
                        selectItem:item
                    })
                }} key={index}>
                    <div className="vip-select-title">{item.title}</div>
                    <div className="vip-select-money" >￥{item.money}</div>
                    <div className="vip-select-desc">{item.desc}</div>
                </div>
            )
        });
        return moneyListHtml;
    };

    payModeHandler = (mode) =>{
        this.setState({payMode:mode})
    }

    modalCloseHandle = () =>{
        this.props.closeHandle();
    }
    modalQaCloseHandle = () =>{
        this.setState({qaVisible:false})
    }

    render() {
        const selectItem = this.state.selectItem;
        const payMode = this.state.payMode;
        const qaDesc = this.state.qaDesc;
        let qrUrl="";
        if(payMode === 1){
            qrUrl = "./image/wx_qrcode.png"
        }else{
            qrUrl = "./image/ali_qrcode.png"
        }


        return (
            <div >
                <div style={{display:this.props.visible?'block':'none'}}>
                    <div className="modal-bg"></div>
                    <div className="vip-modal">
                        <div className="modal-head" >
                            <div className="modal-title">
                                <img src="./image/modal_vip_title.png" className="modal-title-img"/>
                            </div>
                            <div className="modal-close" onClick={this.modalCloseHandle}>
                                <img src="./image/modal_close_icon.png" className="modal-close-img"/>
                            </div>
                        </div>
                        <div className="modal-content">
                            <div className="money-list-title" onClick={()=>{
                                this.setState({qaVisible:true})
                            }}>常见问题?</div>
                            <div className="money-list">
                                {this.getMoneyListHtml()}
                            </div>
                            <div className="pay-mode-info">
                                <div className="vip-pay-mode" >
                                    <div className="vip-pay-wx" style={{backgroundColor:payMode === 1 ? "#fff":"#f0f0f0"
                                    }} onClick={() =>{
                                        this.payModeHandler(1);
                                    }}>
                                        <div className="pay-icon">
                                            <img src="./image/wx_pay_icon.png" className="pay-icon-img"/>
                                        </div>
                                        <div className="pay-mode-name">微信支付</div>
                                    </div>

                                    <div className="vip-pay-ali" style={{backgroundColor:payMode === 2 ? "#fff":"#f0f0f0"
                                    }} onClick={() =>{
                                        this.payModeHandler(2);
                                    }}>
                                        <div className="pay-icon">
                                            <img src="./image/ali_pay_icon.png" className="pay-icon-img"/>
                                        </div>
                                        <div className="pay-mode-name">支付宝支付</div>
                                    </div>
                                </div>
                                <div className="vip-pay-qrcode">
                                    <div className="vip-code">
                                        <img src={qrUrl} className="vip-code-img"/>
                                    </div>
                                    <div className="scan-desc">手机扫一扫支付</div>
                                    <div className="vip-code-info">￥{selectItem.money}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{display:this.state.qaVisible?'block':'none'}}>
                    <div className="vip-modal">
                        <div className="modal-head" >
                            <div className="modal-title">
                                <img src="./image/modal_vip_title.png" className="modal-title-img"/>
                            </div>
                            <div className="modal-close" onClick={this.modalQaCloseHandle}>
                                <img src="./image/modal_close_icon.png" className="modal-close-img"/>
                            </div>
                        </div>
                        <div className="modal-content">
                            <div className="qa-title">常见问题解答</div>
                            <div className="qa-content">
                                <div className="qa-sub-title">问题1：什么是连续包月套餐？</div>
                                <div className="qa-sub-content">回答：购买自动续费服务后，会在每个计费周期到期前24小时，自动在购买账户扣费并延长该计费周期对应的会员有效期。</div>
                                <div className="qa-sub-title">问题2：如何取消自动续费？</div>
                                <div className="qa-sub-content" ref="qaContent"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
