import React, { Component } from "react";
import { hashHistory, Link } from "react-router";
import "./home.css";
import PicSwiper from "../../components/PicSwiper/PicSwiper";
import HeadNav from "../../components/HeadNav/index";
import Footer from "../../components/Footer/index";
const picList = ["./image/home_banner_2.png", "./image/home_banner_3.png"];
class Home extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <HeadNav selectKey="1" />
        <div className="home-main">
          <div className="home-title">
            <img src="/image/banner01.jpg"/>
          </div>
          <div className="home-content">
            <h2 className="con-title">科技创新荣耀</h2>
            <div className="item-box">
              <ul>
                <li>
                  <div>
                    <img src="/image/c01.jpg" alt="" />
                  </div>
                  <h3 className="tit">荣誉</h3>
                </li>
                <li>2013年12月 . TCL通讯获国家重点软件企业称号</li>
                <li>2013年11月 . TCL集团成立国家级（首批）工业设计中心</li>
                <li>2013年10月 . 通力电子获广东省战略性新兴产业骨干和培育企业称号</li>
                <li>2013年10月 . TCL通讯获2013年广东省战略性新兴产业骨干和培育企业</li>
                <li>2013年9月 . TCL集团获（第一批）广东省重点支持大型骨干企业称号</li>
              </ul>
              <ul>
              <li>
                  <div>
                    <img src="/image/c02.jpg" alt="" />
                  </div>
                  <h3 className="tit">新技术，新产品</h3>
                </li>
                <li>2013年12月 . TCL通讯获国家重点软件企业称号</li>
                <li>2013年11月 . TCL集团成立国家级（首批）工业设计中心</li>
                <li>2013年10月 . 通力电子获广东省战略性新兴产业骨干和培育企业称号</li>
                <li>2013年10月 . TCL通讯获2013年广东省战略性新兴产业骨干和培育企业</li>
                <li>2013年9月 . TCL集团获（第一批）广东省重点支持大型骨干企业称号</li>
              </ul>
              <ul>
              <li>
                  <div>
                    <img src="/image/c03.jpg" alt="" />
                  </div>
                  <h3 className="tit">专利奖</h3>
                </li>
                <li>2013年12月 . TCL通讯获国家重点软件企业称号</li>
                <li>2013年11月 . TCL集团成立国家级（首批）工业设计中心</li>
                <li>2013年10月 . 通力电子获广东省战略性新兴产业骨干和培育企业称号</li>
                <li>2013年10月 . TCL通讯获2013年广东省战略性新兴产业骨干和培育企业</li>
                <li>2013年9月 . TCL集团获（第一批）广东省重点支持大型骨干企业称号</li>
              </ul>
            </div>
            <h2 className="con-title title2">关于我们</h2>
            <div className="banner">
              <a href="http://www.tcl.com/" target="_blank" >
                <img src="/image/banner02.jpg" alt="" />
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Home;
