import React, { Component } from 'react';
import './recharge.css';
import {Input,Select} from 'antd';


const Option = Select.Option;
const moneyList=[{type:"1",money:"10"},{type:"2",money:"30"},{type:"3",money:"50"},{type:"4",money:"100"},{type:"5",money:"200"},{type:"6",money:"500"},{type:"7",money:"1000"}]
const gameList=[{key:"1",value:"小鸟OL"},{key:"2",value:"3D开车教室"},{key:"3",value:"宝宝小警察"}];
class Recharge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectMoneyItem:""
        }
    }

    getMoneyListHtml = () => {
        const that = this;
        let moneyListHtml = moneyList.map(function(item,index){
            let bgStyle={};
            const selectMoneyItem = that.state.selectMoneyItem;
            if(selectMoneyItem.type === item.type){
                bgStyle.background="#ff7474";
                bgStyle.color="#ffffff";
            }else{
                bgStyle.background="#ffffff";
                bgStyle.color="#525252";
            }
            return(
                <div className="money-item" style={bgStyle} onClick={()=>{
                    that.setState({
                        selectMoneyItem:item
                    })
                }} key={index}>{item.money}元</div>
            )
        });
        return moneyListHtml;
    };


    getGameListHtml = () => {
        let gameListSelect = gameList.map(function(item,index){
            return(
                <Option value={item.value} key={index}>{item.value}</Option>
            )
        });
        return gameListSelect;
    };

    handleSelectChange = (e) =>{
        console.log(e)
    };

    render() {
        return (
            <div>
                <div className="recharge-contain">
                    <div className="head">
                        <div className="head-title">游戏:充值中心</div>
                        <div className="head-desc">荟币是豪客游戏平台发行的虚拟商品，对应兑换比例：1元=1个荟币，荟币可在游戏中使用。</div>
                    </div>
                    <div className="game-account">
                        <label>充值账户：</label>
                        <Input  placeholder="请输入充值账号" className="input-account"/>
                    </div>
                    <div className="select-game">
                        <label>选择游戏：</label>
                        <Select
                            placeholder="选择游戏"
                            onChange={this.handleSelectChange}
                            className="select-game-option"
                        >
                            {this.getGameListHtml()}
                        </Select>
                    </div>
                    <div className="recharge-money">
                        <div className="recharge-money-title">充值金额</div>
                        <div className="recharge-money-list">
                            {this.getMoneyListHtml()}
                            <div className="clear"></div>
                        </div>

                    </div>
                    <div className="other-money">
                        <label>其他金额：</label>
                        <Input  placeholder="请输入金额" className="input-money"/>
                    </div>

                    <div className="btn-group">
                        <div className="wx-pay-btn">微信充值</div>
                        <div className="ali-pay-btn">支付宝充值</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Recharge;
