import React, { Component } from 'react';
import './matchDetail.css';

class MatchDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {
        return (
            <div>
                <div className="match-detail-contain">
                    <div className="match-detail-title">2019英雄联盟LPL春季赛</div>
                    <p>2019《英雄联盟》职业联赛(简称：LPL)春季赛即将于1月14日17点，在上海虹桥天地演艺中心正式拉开大幕。翻过载誉满满的2018赛季，LPL即将书写全新篇章。iG、RNG、EDG、JDG、TOP、RW、SN、FPX、LGD、Snake、BLG、WE、VG、OMG、SDG以及V5十六支国内一线《英雄联盟》职业战队，将在未来近四个月的时间中，为2019LPL最高荣誉发起冲击。新战队、新赛制、新主场、新机遇，2019LPL春季赛，属于全体LPL战队的全新挑战即将来临！</p>
                    <div className="desc-pic"><img src="./image/match_pic_01.png" alt="" /></div>
                    <div className="desc-pic-title">2019《 英雄联盟》职业联赛春季赛</div>
                    <p>2019LPL春季赛最为显著的变化，便是联赛规模的扩大。SDG与V5的加入，让LPL战队从14支扩展至16支。新鲜血液的注入，无疑为LPL赛场增添了更多可能性。作为2018LDL冠军战队，SDG在德杯冬季赛中首次以LPL战队的身份与广大玩家见面。险些让SN打道回府的他们，也证明了自己可以立足于LPL的不俗实力。V5虽然未正式亮，但在其收入如Corn、Jinoo、Road等一众LPL战将后，也吸引了不少玩家的关注。将经验与能力相整合，在LPL征战多年的老将们能否在V5重拾第二春，让我们拭目以待。</p>
                    <div className="desc-pic"><img src="./image/match_pic_02.png" alt="" /></div>
                </div>
            </div>
        );
    }
}

export default MatchDetail;
