import React,{ Component} from 'react';
import {List} from 'antd';
import './musicDetail.css';
import HeadNav from "../../components/HeadNav";
import Footer from '../../components/Footer/index'

const musicList =[
    {"id":"1","musicName":"采蘑菇的小姑娘","musicPath":"./music/music_01.mp3","musicPic":"./image/music/music_pic_01.png","status":"1"},
    {"id":"2","musicName":"红豆红","musicPath":"./music/music_02.mp3","musicPic":"./image/music/music_pic_02.png","status":"1"},
    {"id":"3","musicName":"大花轿","musicPath":"./music/music_03.mp3","musicPic":"./image/music/music_pic_03.png","status":"1"},
    {"id":"4","musicName":"加入你要认识我","musicPath":"./music/music_04.mp3","musicPic":"./image/music/music_pic_04.png","status":"1"},
    {"id":"5","musicName":"九月九的酒","musicPath":"./music/music_05.mp3","musicPic":"./image/music/music_pic_05.png","status":"1"},
    {"id":"6","musicName":"卖花姑娘","musicPath":"./music/music_06.mp3","musicPic":"./image/music/music_pic_06.png","status":"1"},
    {"id":"7","musicName":"情深谊长","musicPath":"./music/music_07.mp3","musicPic":"./image/music/music_pic_07.png","status":"1"},
    {"id":"8","musicName":"谁不说俺家乡好","musicPath":"./music/music_08.mp3","musicPic":"./image/music/music_pic_08.png","status":"1"},
    {"id":"9","musicName":"望星空","musicPath":"./music/music_09.mp3","musicPic":"./image/music/music_pic_09.png","status":"1"},
    {"id":"10","musicName":"蜗牛与黄鹂鸟","musicPath":"./music/music_10.mp3","musicPic":"./image/music/music_pic_10.png","status":"1"},
    {"id":"11","musicName":"吐鲁番的葡萄熟了","musicPath":"./music/music_01.mp3","musicPic":"./image/music/music_pic_11.png","status":"1"},
    {"id":"12","musicName":"驼铃","musicPath":"./music/music_02.mp3","musicPic":"./image/music/music_pic_12.png","status":"1"},
    {"id":"13","musicName":"外婆的彭湖湾","musicPath":"./music/music_03.mp3","musicPic":"./image/music/music_pic_13.png","status":"1"},
    {"id":"14","musicName":"弯弯的月亮","musicPath":"./music/music_04.mp3","musicPic":"./image/music/music_pic_14.png","status":"1"},
    {"id":"15","musicName":"枉凝眉","musicPath":"./music/music_05.mp3","musicPic":"./image/music/music_pic_15.png","status":"1"},
    {"id":"16","musicName":"小城故事","musicPath":"./music/music_06.mp3","musicPic":"./image/music/music_pic_16.png","status":"1"},
    {"id":"17","musicName":"羞答答的玫瑰静悄悄地开","musicPath":"./music/music_07.mp3","musicPic":"./image/music/music_pic_17.png","status":"1"},
    {"id":"18","musicName":"绣荷包","musicPath":"./music/music_08.mp3","musicPic":"./image/music/music_pic_18.png","status":"1"},
    {"id":"19","musicName":"雁南飞","musicPath":"./music/music_09.mp3","musicPic":"./image/music/music_pic_19.png","status":"1"},
    {"id":"20","musicName":"有一个美丽的传说","musicPath":"./music/music_10.mp3","musicPic":"./image/music/music_pic_20.png","status":"1"}];
const loopIcons=["./image/loop_icon_01.png","./image/loop_icon_02.png","./image/loop_icon_03.png"];
let progressEle;
let rangeEle;
let circleEle;
let audioEle;
let currentTimeEle;
let durationTimeEle;
let playInterval;
let voiceEle;

class MusicDetail extends Component {
    constructor(props) {
        super(props);
        let index = this.props.location.query.index;
        this.state = {
            selectItem: musicList[index],
            loopMode:0,  // 0 随机 1循环 2 单曲
            showVoice:false
        }
    }

    payMusicHandle = (item) => {
    }

    componentDidMount() {
        const that = this;
        progressEle = this.refs.progress;
        rangeEle = this.refs.range;
        circleEle = this.refs.circle;
        audioEle = this.refs.audio;
        currentTimeEle = this.refs.currentTime;
        durationTimeEle = this.refs.durationTime;
        voiceEle = this.refs.voice;

        audioEle.addEventListener("pause", function(e){
            const selectItem = that.state.selectItem;
            selectItem.status = 1;
            that.setState({selectItem:selectItem})
            if(playInterval){
                clearInterval(playInterval)
            }
        }, false);

        audioEle.addEventListener("play", function(e){
            const selectItem = that.state.selectItem;
            selectItem.status = 0;
            that.setState({selectItem:selectItem})
        }, false);

        audioEle.addEventListener("ended", function(e){
            that.nextMusicHandle();
        }, false);


        progressEle.addEventListener("mousedown",function (event) {
            that.changeProgress(event)
        },false)

        circleEle.addEventListener("mousedown",function (event) {
            document.onmousemove=function (ev) {
                that.changeProgress(ev);
            };

            document.onmouseup = function () {
                document.onmousemove = null;
                document.onmouseup = null;
            }
        },false);

        voiceEle.addEventListener("click",function(event){
            that.setState({showVoice:!that.state.showVoice})
        });

        document.addEventListener("click",function(event){
            // if(event.target !== voiceEle && event.target !== voicePanelEle){
            //     that.setState({showVoice:false})
            // }
        });

        this.getProgressWidth();
    }


    playHandle = () =>{
        let that = this;
        const selectItem = this.state.selectItem;
        console.log(selectItem.status)
        if(selectItem.status == "1"){ // 暂停
            selectItem.status = "0";
            this.setState({selectItem:selectItem},() => {
                audioEle.play();
                playInterval = setInterval(that.setProgress,1000);
            });
        }else if(selectItem.status == "0"){ // 播放
            console.log("2345")
            selectItem.status = "1";
            this.setState({selectItem:selectItem},() => {
                audioEle.pause();
            });
        }
    };

    perMusicHandle = () =>{
        let that = this;
        let selectItem = this.state.selectItem;
        const loopMode = this.state.loopMode;
        if(loopMode === 0){
            let nextIndex = this.getRandom();
            selectItem = musicList[nextIndex];
        }else if(loopMode === 1){
            let preIndex = parseInt(selectItem.id ) - 2;
            if(preIndex < 0){
                preIndex = 9;
            }
            selectItem = musicList[preIndex]
        }
        this.setState({selectItem:selectItem},function(){
            that.playHandle();
        })
    };



    nextMusicHandle = () =>{
        let that = this;
        let selectItem = this.state.selectItem;
        const loopMode = this.state.loopMode;
        if(loopMode === 0) {
            let nextIndex = this.getRandom();
            selectItem = musicList[nextIndex];
        }else if(loopMode === 1){
            let nextIndex = parseInt(selectItem.id) ;
            if(nextIndex > 19){
                nextIndex = 0;
            }
            selectItem = musicList[nextIndex]
        }
        this.setState({selectItem:selectItem},function(){
            that.playHandle();
        })
    };

    loopHandle = () => {
      let loopMode = this.state.loopMode;
      loopMode++;
      if(loopMode === 3){
          loopMode = 0;
      }
      this.setState({loopMode:loopMode});
    };

    getRandom = () =>{
        return parseInt(Math.random()*20);
    };


    getProgressWidth = () => {
        let progressWidth = document.body.clientWidth - 640;   // 400 240
        return progressWidth;
    };



    //设置进度的自动移动
    setProgress = () => {
        let progressWidth = this.getProgressWidth();
        rangeEle.style.width=(audioEle.currentTime)/(audioEle.duration)*progressWidth+"px";
        circleEle.style.left=rangeEle.style.width;
        currentTimeEle.innerHTML=this.formatTime(audioEle.currentTime);
        durationTimeEle.innerHTML=this.formatTime(audioEle.duration);
    };

    formatTime = (t) =>{
        let m=Math.floor(t/60);
        let s=Math.floor(t%60);
        if(m<=9)
            m="0"+m;
        if(s<=9)
            s="0"+s;
        return m+":"+s;
    };

    changeProgress = (ev) =>{
        const selectItem = this.state.selectItem;
        if(selectItem.id){
            let progressWidth = this.getProgressWidth();
            let left = ev.clientX - 400;
            if(left < 0){
                left = 0;
            }
            else if (left > progressWidth) {
                left = progressWidth;
            }
            circleEle.style.left = left+"px";
            rangeEle.style.width = left+"px";
            audioEle.currentTime=(left/progressWidth)*audioEle.duration;
            this.setProgress();
        }
    };


    getMusicListHtml = () => {
        let musicListHtml = musicList.map(function(item,index){
            return(
                <div className="music-item" key={index} >
                    <div className="music-index">{index}</div>
                    <div className="music-name">{item.musicName}</div>
                    <div className="music-btn-group">
                        <div className="music-play-btn">
                            <img src="./image/music_pay_icon.png" alt="" />
                        </div>
                        <div className="music-download-btn">
                            <img src="./image/music_download_icon.png" alt="" />
                        </div>
                    </div>

                </div>
            )
        });
        return musicListHtml;
    };


    render() {
        const selectItem = this.state.selectItem;
        return (
            <div >
                <div className="music-contain">
                    <div className="music-title">{selectItem.musicName}</div>
                    <div className="music-pic">
                        <img src={selectItem.musicPic} className="music-pic-img" />
                    </div>
                    <div className="audio-panel">
                        <div className="audio-btn-group">
                            <div className="audio-pre-btn" onClick={()=>{
                                this.perMusicHandle();
                            }}>
                                <img src='./image/music_kuaitui_icon.png' alt=""/>
                            </div>
                            <div className="audio-play-btn" onClick={()=>{
                                this.playHandle();
                            }}>
                                <img src={selectItem.status === 0?"./image/music_stop_icon_1.png":"./image/music_play_icon.png"} alt="" />
                            </div>
                            <div className="audio-next-btn" onClick={()=>{
                                this.nextMusicHandle();
                            }}>
                                <img src='./image/music_kuaijin_icon.png' alt="" />
                            </div>
                        </div>
                        <div className="music-loop" onClick={()=>{
                            this.loopHandle();
                        }}>
                            <img src={loopIcons[this.state.loopMode]} alt="" />
                        </div>
                        <div className="current-time" ref="currentTime">00:00</div>
                        <div className="panel-music-name" >{selectItem.musicName}</div>
                        <div className="progress" ref="progress">
                            <div className="range" ref="range"></div>
                            <div className="circle" ref="circle"></div>
                        </div>
                        <div className="duration-time" ref="durationTime">00:00</div>
                        <div className="music-voice" ref="voice"></div>
                        <div className="music-voice-panel" ref="voicePanel" style={{display:this.state.showVoice ? "block":"none"}}></div>
                    </div>
                    <audio src={selectItem.musicPath} className="music-audio-panel" ref="audio">
                    </audio>
                </div>
            </div>
            );
        }
    }

export default MusicDetail;
