
import React, {Component} from 'react';
import './index.css';
import {Modal,Form,Input,Icon,Button} from "antd";


export class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible:props.visible
        }
    };

    componentWillReceiveProps(nextProps, nextContext) {
        const visible = this.state.visible;
        if(nextProps.visible !== visible){
            this.setState({
                visible:nextProps.visible
            })
        }
    };

    handleOk = (e) => {
        this.props.cancelHandle();
    };

    handleCancel = (e) => {
        this.props.cancelHandle();
    };

    handleSubmit = (e) => {
        this.setState({
            visible: false,
        });
    };


    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <Modal
                    title="登录"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    cancelText="取消"
                    okText="确定"
                    onCancel={this.handleCancel}
                    footer={null}
                    width="300px"
                >
                    <Form onSubmit={this.handleSubmit} className="login-form">
                        <Form.Item>
                            {getFieldDecorator('userName', {
                                rules: [{ required: true, message: '用户名不能为空' }],
                            })(
                                <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="用户名" />
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: '密码不能为空' }],
                            })(
                                <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="密码" />
                            )}
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                登录
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}

export default Form.create()(Login)
