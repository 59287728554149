import React, { Component } from "react";
import "./diseaseList.css";
import HeadNav from "../../components/HeadNav";
import Footer from "../../components/Footer/index";
import Disease from "../../components/Disease/disease";
class DiseaseList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <HeadNav selectKey="9" />
        <div className="disease-contain">
          <div className="disease-list">
            <Disease />
            <div className="clear" />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default DiseaseList;
