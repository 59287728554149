import React, { Component } from 'react';
import './TvPlay.css';
import HeadNav from '../../components/HeadNav';
import Footer from '../../components/Footer/index'
import PicSwiper from '../../components/PicSwiper/PicSwiper';
import VipModal from '../../components/VipModal/index';

const  picList = ["./image/movie_banner_1.png","./image/movie_banner_2.png","./image/movie_banner_3.png"];


class TvPlay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hotPlayList:[],
            vipPlayList:[],
            varietyPlayList:[],
            movieList:[],
            childPayList:[],
            vipModalVisible:false
        }
    }

    componentDidMount() {
        fetch("tcast/v3/selection?model=ios&appVer=3.1.0&functionId=401")
            .then(response =>
                response.json()
            ).then(json => {
                if (json.errorcode == 0) {
                    const result = json.data;
                    let hotPlayList = result[2].list || [];
                    let vipPlayList = result[1].list || [];
                    let movieList = result[4].list || [];
                    let varietyPlayList = result[3].list || [];
                    let childPayList = result[6].list || [];
                    vipPlayList.map(item =>{
                        item.isVip = true;
                        return item;
                    })
                    this.setState({hotPlayList:hotPlayList,
                        vipPlayList:vipPlayList,
                        movieList:movieList,
                        varietyPlayList:varietyPlayList,
                        childPayList:childPayList
                    })
                }
            }
        ).catch(error => {
            console.log(error);
        });
    }

    closeHandle = () =>{
        this.setState({
            vipModalVisible:false
        })
    }

    directHandle = (record) =>{
        if(record.isVip){
            this.setState({vipModalVisible:true});
        }else{
            window.location.href="https://v.qq.com/x/cover/"+record.vid+".html"
        }

    }

    getPlayListHtml = (playList) => {
        let  that = this;
        let preformListHtml = playList.map(function(item,index){
            if(index < 6) {
                let  href="https://v.qq.com/x/cover/"+item.vid+".html"
                let itemStyle = {};
                if (index === 0) {
                    itemStyle.marginLeft = '2%'
                }
                if (index === playList.length) {
                    itemStyle.marginRight = '2%'
                }
                return (
                    <div className="tv-play-list-item" key={index} style={itemStyle} onClick={() =>{
                        that.directHandle(item)
                    }}>
                        <div className="tv-play-pic">
                            <img className="tv-play-img" src={item.pictureUrl}/>
                            <div className="vip-icon" style={{display:item.isVip ? "block":"none"}}>
                                <img src="./image/vip_icon.png" className="vip-icon-img"/>
                            </div>
                        </div>
                        <div className="tv-play-title">{item.title}</div>
                        <div className="tv-play-sTitle ">{item.sTitle}</div>
                    </div>


                )
            }
        });
        return preformListHtml;
    };



    render() {
        const list = this.state.list;
        const hotPlayList = this.state.hotPlayList;
        const vipPlayList = this.state.vipPlayList;
        const varietyPlayList = this.state.varietyPlayList;
        const movieList = this.state.movieList;
        const childPayList = this.state.childPayList;
        return (
            <div>
                <HeadNav selectKey = "7" />

                <div className="tv-contain">
                    <PicSwiper picList={picList} />
                    <div className="tv-list">
                        <div className="play-title">会员专享</div>
                        {this.getPlayListHtml(vipPlayList)}
                    </div>
                    <div className="tv-list">
                        <div className="play-title">热播剧场</div>
                        {this.getPlayListHtml(hotPlayList)}
                    </div>
                    <div className="tv-list">
                        <div className="play-title">强档综艺</div>
                        {this.getPlayListHtml(varietyPlayList)}
                    </div>
                    <div className="tv-list">
                        <div className="play-title">电影大片</div>
                        {this.getPlayListHtml(movieList)}
                    </div>
                    <div className="tv-list">
                        <div className="play-title">少儿精选</div>
                        {this.getPlayListHtml(childPayList)}
                    </div>
                </div>
                <Footer />
                <VipModal visible={this.state.vipModalVisible} closeHandle={this.closeHandle}/>
            </div>
            );
        }
    }

export default TvPlay;
