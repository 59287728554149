import React, { Component } from 'react';
import {Link} from "react-router";
import './match.css';
import HeadNav from '../../components/HeadNav';
import Footer from '../../components/Footer/index'

class Match extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div>
                <HeadNav selectKey="6"/>
                <div className="match-contain">
                    <div className="match-title">2019英雄联盟LPL春季赛</div>
                    <div className="match-pic"><img src="./image/match_pic_01.png" alt="" /></div>
                    <p>2019《英雄联盟》职业联赛(简称：LPL)春季赛即将于1月14日17点，在上海虹桥天地演艺中心正式拉开大幕。翻过载誉满满的2018赛季，LPL即将书写全新篇章。iG、RNG、EDG、JDG、TOP、RW、SN、FPX、LGD、Snake、BLG、WE、VG、OMG、SDG以及V5十六支国内一线《英雄联盟》职业战队，将在未来近四个月的时间中，为2019LPL最高荣誉发起冲击。新战队、新赛制、新主场、新机遇，2019LPL春季赛，属于全体LPL战队的全新挑战即将来临！</p>
                    <Link to="matchDetail">
                        <div className="detail_btn">查看详情</div>
                    </Link>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default Match;
