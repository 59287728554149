import React, { Component } from "react";
import "./drug.css";

const drugList = [
  {
    name: "千金药业妇科千金片",
    picUrl: "http://www.haokefun.com/static/picture/1-200425002R50-L.jpg",
  },
  {
    name: "同仁堂乌鸡白凤丸",
    picUrl: "http://www.haokefun.com/static/picture/1-200425002K20-L.jpg",
  },
  {
    name: "汇仁女金胶囊",
    picUrl: "http://www.haokefun.com/static/picture/1-200425002F40-L.jpg",
  },
  {
    name: "气血和胶囊",
    picUrl: "http://www.haokefun.com/static/picture/1-2004250026270-L.jpg",
  },
  {
    name: "云南白药气雾剂",
    picUrl: "http://www.haokefun.com/static/picture/1-2004250025470-L.jpg",
  },
  {
    name: "优思明 屈螺酮炔雌醇片",
    picUrl: "http://www.haokefun.com/static/picture/1-2004250024530-L.jpg",
  },
  {
    name: "九芝堂 六味地黄丸(浓缩丸 ",
    picUrl: "http://www.haokefun.com/static/picture/1-2004250022490-L.jpg",
  },
  {
    name: "蔓迪 米诺地尔酊",
    picUrl: "http://www.haokefun.com/static/picture/1-2004250013340-L.jpg",
  },
  {
    name: "圣华曦 盐酸乐卡地平片",
    picUrl: "http://www.haokefun.com/static/picture/1-1Z50G349250-L.jpg",
  },
];

class Drug extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getDrugListHtml = () => {
    let drugListHtml = drugList.map(function(item, index) {
      return (
        <div className="drug-item" key={index}>
          <img src={item.picUrl} className="drug-img" alt="" />
          <div className="drug-name">{item.name}</div>
        </div>
      );
    });
    return drugListHtml;
  };

  render() {
    return <div>{this.getDrugListHtml()}</div>;
  }
}

export default Drug;
