import React,{ Component} from 'react';
import {List} from 'antd';
import './music.css';
import HeadNav from "../../components/HeadNav";
import Footer from '../../components/Footer/index'
import {Link} from "react-router";

const musicList =[
    {"id":"1","musicName":"采蘑菇的小姑娘","musicPath":"./music/music_01.mp3","status":"1"},
    {"id":"2","musicName":"红豆红","musicPath":"./music/music_02.mp3","status":"1"},
    {"id":"3","musicName":"大花轿","musicPath":"./music/music_03.mp3","status":"1"},
    {"id":"4","musicName":"加入你要认识我","musicPath":"./music/music_04.mp3","status":"1"},
    {"id":"5","musicName":"九月九的酒","musicPath":"./music/music_05.mp3","status":"1"},
    {"id":"6","musicName":"卖花姑娘","musicPath":"./music/music_06.mp3","status":"1"},
    {"id":"7","musicName":"情深谊长","musicPath":"./music/music_07.mp3","status":"1"},
    {"id":"8","musicName":"谁不说俺家乡好","musicPath":"./music/music_08.mp3","status":"1"},
    {"id":"9","musicName":"望星空","musicPath":"./music/music_09.mp3","status":"1"},
    {"id":"10","musicName":"蜗牛与黄鹂鸟","musicPath":"./music/music_10.mp3","status":"1"},
    {"id":"11","musicName":"吐鲁番的葡萄熟了","musicPath":"./music/music_01.mp3","status":"1"},
    {"id":"12","musicName":"驼铃","musicPath":"./music/music_02.mp3","status":"1"},
    {"id":"13","musicName":"外婆的彭湖湾","musicPath":"./music/music_03.mp3","status":"1"},
    {"id":"14","musicName":"弯弯的月亮","musicPath":"./music/music_04.mp3","status":"1"},
    {"id":"15","musicName":"枉凝眉","musicPath":"./music/music_05.mp3","status":"1"},
    {"id":"16","musicName":"小城故事","musicPath":"./music/music_06.mp3","status":"1"},
    {"id":"17","musicName":"羞答答的玫瑰静悄悄地开","musicPath":"./music/music_07.mp3","status":"1"},
    {"id":"18","musicName":"绣荷包","musicPath":"./music/music_08.mp3","status":"1"},
    {"id":"19","musicName":"雁南飞","musicPath":"./music/music_09.mp3","status":"1"},
    {"id":"20","musicName":"有一个美丽的传说","musicPath":"./music/music_10.mp3","status":"1"}];
const loopIcons=["./image/loop_icon_01.png","./image/loop_icon_02.png","./image/loop_icon_03.png"];
let progressEle;
let rangeEle;
let circleEle;
let audioEle;
let currentTimeEle;
let durationTimeEle;
let playInterval;
let voiceEle;

class Music extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectItem: musicList[0],
            loopMode:0,  // 0 随机 1循环 2 单曲
            showVoice:false
        }
    }

    getMusicListHtml = () => {
        let musicListHtml = musicList.map(function(item,index){
            return(
                <div className="music-item" key={index} >
                    <div className="music-index">{index}</div>
                    <div className="music-name">{item.musicName}</div>
                    <div className="music-btn-group">
                        <Link to={{pathname:"/music_detail",query:{index:index}}}>
                            <div className="music-play-btn">
                                <img src="./image/music_pay_icon.png" alt="" />
                            </div>
                        </Link>
                        <div className="music-download-btn">
                            <img src="./image/music_download_icon.png" alt="" />
                        </div>
                    </div>
                </div>
            )
        });
        return musicListHtml;
    };


    render() {
        const selectItem = this.state.selectItem;
        return (
            <div >
                <HeadNav selectKey="2"/>
                <div className="music-contain">
                    <div className="music-title">热门歌曲列表</div>
                    <div className="music-list">
                         <List
                            dataSource={musicList}
                            pagination={{
                                onChange: page => {
                                    console.log(page);
                                },
                                pageSize: 10,
                            }}
                            renderItem={(item,index) => (<List.Item>
                                <div className="music-item" >
                                    <div className="music-index">{index+1}</div>
                                    <div className="music-name">{item.musicName}</div>
                                    <div className="music-btn-group">
                                        <Link to={{pathname:"/music_detail",query:{index:index}}}>
                                            <div className="music-play-btn" >
                                                <img src={item.id === selectItem.id && item.status === "0"?"./image/music_stop_icon.png":"./image/music_pay_icon.png"} alt="" />
                                            </div>
                                        </Link>
                                        <div className="music-download-btn">
                                            <a href={item.musicPath} download>
                                                <img src="./image/music_download_icon.png" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </List.Item>)}
                        />
                        <div className="clear" />
                    </div>
                </div>
                <Footer />
            </div>
            );
        }
    }

export default Music;
