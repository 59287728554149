import React, { Component } from 'react';
import { Link } from 'react-router';
import './preform.css';
import HeadNav from '../../components/HeadNav';
import {List, Spin} from "antd";
import InfiniteScroll from 'react-infinite-scroller';
import Footer from '../../components/Footer/index'
import VipModal from '../../components/VipModal/index';

const preformList =[{"preformPicUrl":"./image/preform_pic_1.png",
        "preformName":"方块熊简笔画-蝴蝶","gameSummary":"《方块熊简笔画》是方块熊乐园新推出的一款教小朋友们学画简笔画的节目，节目中选了大量好玩又简单的画作，有自然风景、虫鱼鸟兽也有生活中常见的事物，教小朋友们熟悉日常事物的同时还能培养小朋友们的艺术天分。"
    },{"preformPicUrl":"./image/preform_pic_2.png",
    "preformName":"麦杰克儿歌-美丽新世界","gameSummary":"麦杰克儿歌，由资深儿童教育心理学专家，顶级的动画及音乐制作团队联手打造，根据04岁幼儿的视听觉发展规律及语言认知能力，诚意制作有营养，有趣味，有审美，有创意，有知识的儿歌。"
    },{"preformPicUrl":"./image/preform_pic_3.png",
        "preformName":"兔小蛋-急救机器人Amber出动","gameSummary":"兔小蛋玩具专辑为学龄前儿童提供了丰富的玩玩具教学视频内容，里面的趣味玩具可多着呢！如小巧精美的汽车玩具，厨房玩具、沙滩玩具、神奇百变的乐高积木、拼装等等。"
    }];


class Preform extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list:preformList,
            hasMore:true,
            loading:false,
            vipModalVisible:false

        }
    }

    getPreformListHtml = () => {
        let preformListHtml = preformList.map(function(item,index){
            let itemStyle={}
            if(index > 0){
                itemStyle.marginTop = "40px"
            }
            let link = "/preform_detail?index="+index;
            return(
                <div className="preform-item" key={index} style={itemStyle}>
                    <div className="preform-pic">
                        <img src={item.preformPicUrl}  alt=""/>
                    </div>
                    <div className="preform-info">
                        <div className="preform-name">{item.preformName}</div>
                        <div className="preform-summary-info">
                            <div className="summary-title">剧情简介</div>
                            <div className="preform-summary">{item.gameSummary}</div>
                            <Link to={link}>
                                <div className="take-detail-btn">查看</div>
                            </Link>
                        </div>
                    </div>
                </div>
            )
        });
        return preformListHtml;
    };

    handleInfiniteOnLoad = () =>{
        // console.log("handleInfiniteOnLoad");
        // let list = this.state.list;
        // this.setState({loading:true,list :list.concat(gameList)});
        // setTimeout(()=>{
        //     this.setState({loading:false});
        // },2000)
    };

    closeHandle = () =>{
        this.setState({
            vipModalVisible:false
        })
    }

    render() {
        const list = this.state.list;
        return (
            <div>
                <HeadNav selectKey = "4" />
                <div className="preform-contain">
                    <div className="preform-list">
                        <InfiniteScroll
                            initialLoad={false}
                            pageStart={0}
                            loadMore={this.handleInfiniteOnLoad}
                            hasMore={!this.state.loading && this.state.hasMore}
                            useWindow={false}
                        >
                            <List
                                dataSource={list}
                                renderItem={(item,index) => (
                                    <List.Item key={item.id}>
                                        <div className="preform-item" key={index} >
                                            <div className="preform-pic">
                                                <img src={item.preformPicUrl}  alt="" />
                                            </div>
                                            <div className="preform-info">
                                                <div className="preform-name">{item.preformName}</div>
                                                <div className="preform-summary-info">
                                                    <div className="summary-title">剧情简介</div>
                                                    <div className="preform-summary">{item.gameSummary}</div>
                                                    <div className="preform-btn-group">
                                                        {/*<Link to={{pathname:"/member_buy",query:{index:index}}}>*/}
                                                            <div className="preform-buy-btn" onClick={() =>{
                                                                this.setState({vipModalVisible:true})
                                                            }}>会员购买</div>
                                                        {/*</Link>*/}
                                                        <Link to={{pathname:"/preform_detail",query:{index:index}}}>
                                                            <div className="preform-detail-btn">播放</div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </List.Item>
                                )}
                            >
                                {this.state.loading && this.state.hasMore && (
                                    <div className="loading-container">
                                        <Spin />
                                    </div>
                                )}
                            </List>
                        </InfiniteScroll>
                    </div>
                </div>
                <Footer />
                <VipModal visible={this.state.vipModalVisible} closeHandle={this.closeHandle}/>
            </div>
            );
        }
    }

export default Preform;
