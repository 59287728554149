import React, { Component } from 'react';
import './comicDetail.css';
import PicSwiper from '../../components/PicSwiper/PicSwiper';

const comicDetail=[{
    "comicName":"逆五行",
    "comicPicList":["./image/comic_pic_1/pimg1.jpg","./image/comic_pic_1/pimg2.jpg","./image/comic_pic_1/pimg5.jpg","./image/comic_pic_1/pimg6.jpg","./image/comic_pic_1/pimg7.jpg","./image/comic_pic_1/pimg8.jpg"],
    "comicDesc":"上古五神（青龙白虎朱雀玄武黄藏）缔造的这个世界，万物均由具有形态的“质”和不具有形态的“气”构成。“质”分别由五种基本元素：“金”“木”“水”“火”“土”构成，并在“气”的作用下规律的运动。构成“质”的五种元素，被人类记载为“五行”。“气”分“阴”“阳”，依附于“质”，维系着世间万物的平衡。经过漫长的岁月，支配“气”的神灵和支配“质“的人类之间的平衡逐渐被打破，贪婪的人类对蕴藏丰富的“气”..."
},{
    "comicName":"百炼成神",
    "comicPicList":["./image/comic_pic_2/pimg1.jpg","./image/comic_pic_2/pimg2.jpg","./image/comic_pic_2/pimg3.jpg","./image/comic_pic_2/pimg4.jpg","./image/comic_pic_2/pimg5.jpg","./image/comic_pic_2/pimg6.jpg","./image/comic_pic_2/pimg7.jpg","./image/comic_pic_2/pimg8.jpg"],
    "comicDesc":"千锤百炼方可成神，少年罗征被打成神的奇幻之旅！"
},{
    "comicName":"隐山梦谈",
    "comicPicList":["./image/comic_pic_3/pimg1.jpg","./image/comic_pic_3/pimg2.jpg","./image/comic_pic_3/pimg3.jpg","./image/comic_pic_3/pimg4.jpg","./image/comic_pic_3/pimg5.jpg","./image/comic_pic_3/pimg6.jpg","./image/comic_pic_3/pimg8.jpg"],
    "comicDesc":"一场隐匿于山雨中的古风唯美怪谈，一段如梦似幻的爱情故事。上元夜，公主煜姬邂逅神秘美男子阿九，痴迷于他的倾世容颜而决定下嫁于他......公主要怎样才能得到阿九的爱，有难言之隐的阿九将如何表达自己的心意？总有一些爱与温暖，在默默地陪时日一起成长蔓延。"
},{
    "comicName":"楚医生也要谈恋爱",
    "comicPicList":["./image/comic_pic_4/pimg1.jpg","./image/comic_pic_4/pimg2.jpg","./image/comic_pic_4/pimg3.jpg","./image/comic_pic_4/pimg4.jpg","./image/comic_pic_4/pimg5.jpg","./image/comic_pic_4/pimg6.jpg","./image/comic_pic_4/pimg7.jpg","./image/comic_pic_4/pimg8.jpg"],
    "comicDesc":"十年苦恋，薄欢用生命中一半的时光追逐着那个叫楚泽的男人，他对她的好，让她不能自拔，可是，一次次隐晦的告白换来的，都是冷静的拒绝。等她终于下定决心不再纠缠的时候，楚泽才后知后觉的发现，他的生命里没有了那个丫头的存在，居然变得那么苍白。鼓起勇气，楚医生决定，好好的谈一场恋爱，好好的宠爱他的丫头。"
},{
    "comicName":"约定的梦幻岛",
    "comicPicList":["./image/comic_pic_5/pimg1.jpg","./image/comic_pic_5/pimg2.jpg","./image/comic_pic_5/pimg3.jpg","./image/comic_pic_5/pimg4.jpg","./image/comic_pic_5/pimg5.jpg","./image/comic_pic_5/pimg6.jpg","./image/comic_pic_5/pimg7.jpg","./image/comic_pic_5/pimg8.jpg","./image/comic_pic_5/pimg9.jpg","./image/comic_pic_5/pimg10.jpg"],
    "comicDesc":"约定的梦幻岛漫画 ，妈妈说外面的世界好可怕，我不信； 但是那一天、我深深地体会到了妈妈说的是真的！ 因为不仅外面的世界、就连妈妈也好可怕……"
},{
    "comicName":"钻石王牌",
    "comicPicList":["./image/comic_pic_6/pimg1.jpg","./image/comic_pic_6/pimg2.jpg","./image/comic_pic_6/pimg3.jpg","./image/comic_pic_6/pimg4.jpg","./image/comic_pic_6/pimg5.jpg","./image/comic_pic_6/pimg6.jpg","./image/comic_pic_6/pimg7.jpg","./image/comic_pic_6/pimg8.jpg","./image/comic_pic_6/pimg9.jpg","./image/comic_pic_6/pimg10.jpg"],
    "comicDesc":"缺少「王牌投手」的青道高中，为了挖掘能够上场的新人，因此举行了一年级与二、三年级的对抗赛。正当比赛一面倒时，荣纯跳出来提高了一年级低沉的士气。教练看到荣纯对於胜利的执著，因此命令他上场投球。 但对於自己的投球特色毫无头绪的泽村，看来要升上一军还得经过一翻苦战。"
}]



class ComicDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {
        let index = this.props.location.query.index;
        return (
            <div className="detail-contain">
                <div className="comic-title">{comicDetail[index].comicName}</div>
                <div className="pic-swiper">
                    <PicSwiper picList={comicDetail[index].comicPicList} type="1" showDots="false"/>
                </div>
                <div className="detail-desc">{comicDetail[index].comicDesc}</div>
            </div>
            );
        }
    }

export default ComicDetail;
