import React, { Component } from 'react';
import './memberBuy.css';


const selectList=[{type:"1",title:"连续包月",money:"15",desc:"15元/月"},
    {type:"2",title:"1个月",money:"20",desc:"20元/月"},
    {type:"3",title:"3个月",money:"60",desc:"20元/月"},
    {type:"4",title:"1年",money:"240",desc:"240元/年"}]

class MemberBuy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectItem:{}
        }
    }


    getMoneyListHtml = () => {
        const that = this;
        let moneyListHtml = selectList.map(function(item,index){
            let bgStyle={};
            let showStyle={};
            let isSelect = false;
            const selectItem = that.state.selectItem;
            if(selectItem.type === item.type){
                 bgStyle.border="1px solid #eac714";
                 bgStyle.background="url(./image/buy_bg.png) 0/ 100% 100%";
            }else{
                bgStyle.border="1px solid #fff";
                bgStyle.background="url(./image/buy_bg.png) 0/ 100% 100%";
            }
            return(
                <div className="select-item" style={bgStyle} onClick={()=>{
                    that.setState({
                        selectItem:item
                    })
                }} key={index}>
                    <div className="select-title">{item.title}</div>
                    <div className="select-money" >￥{item.money}</div>
                    <div className="select-desc">{item.desc}</div>
                </div>
            )
        });
        return moneyListHtml;
    };

    handleSelectChange = (e) =>{
        console.log(e)
    }

    render() {
        return (
            <div className="buy-contain">
                <div className="head">
                    <div className="head-title">会员购买中心</div>
                </div>

                <div className="buy-money">
                    <div className="buy-money-list">
                        {this.getMoneyListHtml()}
                        <div className="clear"></div>
                    </div>
                </div>

                <div className="pay-mode">
                    <div className="pay-mode-item">
                        <div className="mode-title">微信扫码购买</div>
                        <div className="mode-qrcode">
                            <img src="./image/wx_qrcode.png" alt="" />
                        </div>
                    </div>
                    <div className="pay-mode-item">
                        <div className="mode-title">支付宝扫码购买</div>
                        <div className="mode-qrcode">
                            <img src="./image/ali_qrcode.png" alt="" />
                        </div>
                    </div>
                    <div className="clear"></div>
                </div>
            </div>
        );
    }
}

export default MemberBuy;
